import React from 'react';
import Image from "next/image";
import RestrictedLink from "@/components/common/RestrictedLink";
import {BUY_NOW_URL} from "@/lib/constants.mjs";
import packageJson from '@/package.json';

// json structure ripped from taboo-timer translation data
const footer = {
		links: [
		{
			"text": "Consumer Care",
			"href": "https://support.trivialpursuit.com",
			"target": "_blank"
		},
		{
			"text": "Where To Buy",
			"href": BUY_NOW_URL,
			"target": "_blank",
			"external": true
		},
		{
			"text": "Corporate",
			"href": "https://hasbro.gcs-web.com/corporate",
			"target": "_blank"
		},
		{
			"text": "Privacy Policy",
			"href": "https://docs.hasbro.com/en-us/TrivialPursuit/privacy",
			"target": "_blank"
		},
		{
			"text": "Terms and Conditions of Use",
			"href": "https://docs.hasbro.com/en-us/TrivialPursuit/terms",
			"target": "_blank"
		},
		{
			"text": "California Privacy Rights & Notices",
			"href": "https://docs.hasbro.com/en-us/legal/privacy#H",
			"target": "_blank"
		},
		{
			"text": "Do Not Sell My Personal Information",
			"href": "https://docs.hasbro.com/en-us/legal/privacy#right-to-opt",
			"target": "_blank"
		},
		{
			"text": "Responsible Disclosure Policy",
			"href": "https://docs.hasbro.com/upload/legal/responsible_disclosure_2020.pdf",
			"target": "_blank"
		},
		{
			"text": "Data Subject Rights",
			"href": "https://docs.hasbro.com/en-us/privacy_rights",
			"target": "_blank"
		}
	]
}

const Footer = ({links = [], showESRB = false}) => {
	const commitId = process.env.NEXT_PUBLIC_APP_VERSION ? process.env.NEXT_PUBLIC_APP_VERSION : 'local';
	return (
		<div className={'flex flex-col items-center justify-start md:justify-center w-full h-full gap-4 p-4 overflow-y-auto'}>
			<ul className={'flex flex-col flex-wrap items-center justify-center lg:gap-2 lg:text-lg gap-0'}>
				{links?.map((l, i) => <li key={i}>
					{l.external ? (
						<RestrictedLink href={l.href} className={'text-blue-500'} target={l.target || "_blank"}>{l.text}</RestrictedLink>
					) : (
						<a className={'text-blue-500'} target={l.target || "_blank"} href={l.href}>{l.text}</a>
					)}	
				</li>)}
			</ul>
			<div className={'lg:w-9/12'}>
				<div className={'text-sm pt-4'}><span className={'font-bold'}>Disclaimer</span>: This is an alpha version of Trivial Pursuit Infinite, which means it&apos;s not going to be perfect - but it is still going to be wildly fun. We&apos;re working to make the experience better every single day, so enjoy being an early adopter and stay tuned for exciting updates coming soon.</div>
				<div className={'text-sm pt-4'}>&copy;2024 Hasbro. All rights reserved. All audio, visual and textual content on this site (including all names, characters, images, trademarks and logos) are protected by trademarks, copyrights and other Intellectual Property rights owned by Hasbro or its subsidiaries, licensors, licensees, suppliers and accounts.</div>
				<div className={'text-sm pt-4'}>version {packageJson.version} ({commitId})</div>
			</div>

			<div className={'flex items-center justify-center gap-4 p-4 w-full'}>
				<div className={'min-w-[66px]'}>
					<Image src={'/hasbro-logo.png'} alt={"Hasbro Logo"} width={155/2} height={169/2} />
				</div>
				{showESRB && <div className={'min-w-[66px]'}>
					<RestrictedLink href={'https://www.esrb.org/confirm/hasbro-confirmation.aspx'} target={'_blank'}>
						<Image src="/ESRBlogo_adult.png" alt="ESRB Privacy Certified Logo" width={66} height={89} />
					</RestrictedLink>
				</div>}
			</div>
		</div>
	)
}

const InfoModal = () => {
	return (
		<div className={'h-full flex flex-col'}>
			<Footer {...footer}/>
		</div>
	);
};

export default InfoModal;
