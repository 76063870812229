import React from 'react';
import Link from "next/link";

export const TYPE_PLAY = 'play';
export const TYPE_SHOP = 'shop now';
export const TYPE_CANCEL = 'cancel';
export const TYPE_CREATE = 'create';
export const TYPE_BACK = 'back';
export const TYPE_GO_BACK = 'Go back';
export const TYPE_CONTINUE = 'Continue';
export const TYPE_SUBMIT = 'Submit';
export const TYPE_REVIEW = 'Review';
export const TYPE_PLAY_LIVE = 'Play now!';

const PanelButton = ({type=TYPE_PLAY, bgColor, textColor, href='', disabled=false, onClick}) => {
	const handleClick = event => {
		if (!disabled && onClick) {
			event.preventDefault()
			onClick()
			return false
		}
	}

	let className = 'w-[144px] h-[36px] m-1 xs:m-0 rounded flex items-center justify-center uppercase font-gExtBlack '
	if (disabled) {
		className += 'disabled:opacity-20 '
	}
	switch (type) {
		case TYPE_CREATE:
			className += `${bgColor ?? 'bg-playlist'} ${textColor ?? 'text-white'} hover:bg-secondary hover:text-white disabled:opacity-30`
			break;
		case TYPE_CANCEL:
			className += `${bgColor ?? 'bg-white'} ${textColor ?? 'text-daily'} hover:bg-secondary hover:text-white`
			break;
		case TYPE_SHOP:
			className += `${bgColor ?? 'bg-primary'} ${textColor ?? 'text-white'} hover:bg-secondary hover:text-white`
			break;
		case TYPE_BACK:
			className += `${bgColor ?? 'bg-white'} ${textColor ?? 'text-white'} bg-opacity-25 hover:bg-secondary hover:text-white`
			break;
		case TYPE_GO_BACK:
			className += `${bgColor ?? 'bg-white border border-primary'} ${textColor ?? 'text-primary'} bg-opacity-25 hover:bg-secondary hover:text-white`
			break;
		case TYPE_CONTINUE:
			className += `${bgColor ?? 'bg-primary'} ${textColor ?? 'text-white'} hover:bg-secondary hover:text-white`
			break;
		case TYPE_SUBMIT:
			className += `${bgColor ?? 'bg-primary'} ${textColor ?? 'text-white'} hover:bg-secondary hover:text-white`
			break;
		case TYPE_REVIEW:
			className += `${bgColor ?? 'bg-primary'} ${textColor ?? 'text-white'} hover:bg-secondary hover:text-white`
			break;
		case TYPE_PLAY_LIVE:
			className += `${bgColor ?? 'bg-arts'} ${textColor ?? 'text-white'} hover:bg-secondary hover:text-white`
			break;
		case TYPE_PLAY:
		default:
			className += `${bgColor ?? 'bg-primary'} ${textColor ?? 'text-white'} hover:bg-secondary hover:text-white`
			break;
	}
	const testId = type?type==TYPE_SHOP?"shop_now":type:"panel";
	return (
		<Link href={href} onClick={handleClick}>
			<button className={className} disabled={disabled} data-testid={`button-${testId}`}>{type}</button>
		</Link>
	);
};

export default PanelButton;
