import Head from 'next/head'
 
function MetaTags({title="Trivial Pursuit Infinite"}) {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0"
        />
        <meta name="google-adsense-account" content="ca-pub-7520484429350419"/>
        <meta name="keywords" content="Trivial Pursuit, Trivia, AI, Hasbro" />
        <meta name="author" content="Hasbro, Inc." />
        <meta name="description" content="Play Trivial Pursuit Infinite, the free online game that offers daily challenges and AI-generated trivia questions on any topic you can think of. Test your knowledge in this fun and engaging game." />
        <meta property="og:title" content={title} />
        <meta property="og:description" content="Play Trivial Pursuit Infinite, the free online game that offers daily challenges and AI-generated trivia questions on any topic you can think of. Test your knowledge in this fun and engaging game." />
        <meta property="og:url" content="https://www.trivialpursuit.com" />
        <meta property="og:image" content="https://www.trivialpursuit.com/thumb.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="1200" />
        <title>{title}</title>
      </Head>
    </>
  )
}
 
export default MetaTags