import React from 'react';

const CloseButton = ({onClick, className}) => {
	return (
		<button className={`
			flex items-center justify-center
			text-2xl font-gBold w-8 h-8 rounded-full
			hover:text-danger
			cursor-pointer
			${className}
			`} onClick={onClick} aria-label="Close" data-testid="close" 
		>
			<svg
				aria-hidden={true}
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				strokeWidth={1.5}
				stroke="currentColor"
				className="h-8 w-8"
			>
				<path
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
				/>
			</svg>
		</button>
	);
};

export default CloseButton;
