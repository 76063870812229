import React, {useState, forwardRef} from 'react';
import PanelModal from "../modals/PanelModal";
import PanelButton from "../panels/PanelButton";

const RestrictedLink = forwardRef(function Restrict({href, target="_blank", className, children}, ref) {
	const [showModal, setShowModal] = useState(false);

	/**
	 * shows the modal dialog
	 * triggered by user clicking the link
	 * @param event
	 */
	const handleClick = event => {
		event.preventDefault();
		setShowModal(true);
	};

	/**
	 * continue to specified destination
	 * called when modal successfully resolves with a positive result
	 **/
	const onOk = () => {
		window.open(href, target);
		setShowModal(false);
	}

	/**
	 * dismiss the modal. do not proceed to target destination
	 * called when modal is canceled or otherwise resolves with a negative result
	 * @return {boolean}
	 */
	const onCancel = () => {
		setShowModal(false);
		return true;
	};

	return (<>
		<a ref={ref} className={className} href={href} target={target} onClick={handleClick} aria-hidden={true}>{children}</a>
		{/* {showModal && <StandardBumper onOk={onOk} onCancel={onCancel}/>} */}
		<PanelModal action={'buy_dialog_view'} label={'Buy Dialog View'} open={showModal} setOpen={onCancel} bgTheme={"bg-geography"}>
				<div className={`font-gReg text-base flex flex-col gap-4`}>
					<h3 className={`relative top-0 -mt-4 font-gBlack text-center uppercase text-base w-80 mx-auto`}>You are now leaving trivialpursuit.com</h3>
					<p>Hasbro does not control and is not responsible for the availability of, or content on, linked third party websites.</p>
					<p>Please be aware that your use of such third party&apos;s linked website is subject to their privacy policy and terms of use, which may differ from those of Hasbro.</p>
					<p>As such, we encourage you to read the third party&apos;s privacy policy and terms of use closely.</p>
					<div className={`flex justify-around my-2`}>
						<PanelButton type={"Go back"} onClick={onCancel} data-testid={`button-goBack`}/>
						<PanelButton type={"Continue"} onClick={onOk} data-testid={`button-continue`}/>
					</div>
				</div>
			</PanelModal>
	</>
	);
});

export default RestrictedLink;