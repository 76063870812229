import React from 'react';
import dynamic from "next/dynamic";
import packageJson from '../../package.json';
import styles from './Page.module.css'
import {Toaster} from "react-hot-toast";
import HeaderBar from "@/components/common/HeaderBar";
import Head from "next/head";
import { getPublicConfig } from '@/pages/api/config';
import { Adsense } from '@ctrl/react-adsense';
import MetaTags from "@/components/common/MetaTags";
import { PLAYLIST_DETAILS } from "@/lib/constants";
// GoogleTagManager should only load within the browser
const GoogleTagManager = dynamic(import("@/components/common/GoogleTagManager"), { ssr: false });
const DataDogRUM = dynamic(import("@/components/common/DataDogRUM"), { ssr: false });
const AdSenseScript = dynamic(import("@/components/common/AdSense"), { ssr: false });

const Page = ({mode='daily', topAd=false, pageTitle="Trivial Pursuit Infinite", type=null,  children}) => {
	const config = {
		gaTrackingId: getPublicConfig('gaTrackingId'),
		ddEnabled: getPublicConfig('ddEnabled'),
		ddApplicationId: getPublicConfig('ddApplicationId'),
		ddClientToken: getPublicConfig('ddClientToken'),
		ddSampleRate: getPublicConfig('ddSampleRate'),
		ddSessionReplaySampleRate: getPublicConfig('ddSessionReplaySampleRate'),
		environment: getPublicConfig('environment'),
		adSensePublisherId: getPublicConfig('adSensePublisherId'),
	}
	const bgStyle = (type && PLAYLIST_DETAILS[type])?styles[type]:styles[mode];
	return (
		<main className={`flex min-h-screen flex-col items-center justify-between ${bgStyle}
			bg-gradient-to-b from-[#50197D] to-[#000000] via-[#3B125C] via-60%`
		}>
			<MetaTags title={pageTitle}/>
			<Toaster />
			<GoogleTagManager gaTrackingId={config.gaTrackingId} />
			<DataDogRUM
				enabled={config.ddEnabled}
				applicationId={config.ddApplicationId}
				clientToken={config.ddClientToken}
				sessionSampleRate={config.ddSampleRate}
				sessionReplaySampleRate={config.ddSessionReplaySampleRate}
				env={config.environment}
				version={packageJson.version}/>
			<AdSenseScript client={config.adSensePublisherId} />
				{topAd && (
					<Adsense 
						client={config.adSensePublisherId} 
						slot='5059741633'
						format='auto'
						layout='display'
						responsive='true'
					/>
				)}
			<HeaderBar />	
			{children}
		</main>
	);
};

export default Page;
