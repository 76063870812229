import React from 'react';
import Link from "next/link";

// svg exported directly from Figma. We may need to manipulate this for hover states
// svg size is 32 x 32
import HelpSVG from "@/components/icons/help-button.svg"

const HelpButton = ({href='', dataTestId="help-button"}) => <Link href={href} data-testid={dataTestId} aria-label="Help">
	<div className={'absolute m-2 top-0 right-0 w-[32px] h-[32px] hover:opacity-50 z-10'}><HelpSVG /></div>
</Link>

const Panel = ({helpHref, backgroundImage, midgroundImage, grow=false, helpTestId="help-button", className='', children}) => {
	const bg = backgroundImage ? `${backgroundImage} bg-cover` : 'bg-white bg-opacity-70 backdrop-blur';
	const xl = grow ? 'xl:min-w-none xl:max-w-none' : '';
	return (
		<div className={`${className} z-auto relative ${bg} p-2 border border-white rounded-md min-w-[320px] w-full max-w-[480px] min-h-[50px] ${xl} flex items-center justify-center overflow-hidden`}>
			{midgroundImage && <div className={'absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center overflow-hidden opacity-75 pointer-events-none'}>
				{midgroundImage}
			</div>}
			<div className={'z-10 w-full'}>{children}</div>
			{helpHref && <HelpButton href={helpHref}  dataTestId={helpTestId}/>}
		</div>
	);
};

export default Panel;
