import React, { Fragment, useEffect } from "react";
import Panel from "../panels/Panel";
import CloseButton from "@/components/common/buttons/CloseButton";
import cn from "classnames";
import { Dialog, Transition } from "@headlessui/react";
import { STORAGE_KEY, sendGtagEvent } from "@/lib/utils";
//bgTheme - to configure background color theme of panel
const PanelModal = ({
  bgTheme = "bg-geography",
  action = 'share_dialog_view',
  label = 'Share Dialog View',
  open,
  setOpen,
  title,
  children,
}) => {

  useEffect(() => {
    // send GTag event for entering the page..
    const uid = localStorage.getItem(STORAGE_KEY);
    sendGtagEvent({
      action: action,
      category: 'engagement',
      label: label,
      value: 'view',
      user_id: uid
    });
  }, [])

  if (!open) {
    return null;
  }
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[2000] "
        onClose={() => {
          setOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/80" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center p-2">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className={cn("min-w-[320px] w-full max-w-[480px] min-h-[50px] transform overflow-hidden rounded-2xl p-2 text-left align-middle shadow-xl transition-all", bgTheme)}>
                <Panel>
                  <div className="flex items-center justify-between">
                    {title && (
                      <h3 className="text-xl font-semibold text-primary">
                        {title}
                      </h3>
                    )}
                    <CloseButton
                      type="button"
                      className="text-primary bg-transparent rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
                      onClick={() => {
                        setOpen(false);
                      }}
                    />
                  </div>
                  <div className="p-2 text-primary">{children}</div>
                </Panel>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PanelModal;
