import React from 'react';

const Hamburger = ({onClick, className}) => {
	return (
		<button className={`
		text-5xl font-gBold w-8
		mt-2 mr-2 mb-2 ml-2
		text-white 
		hover:opacity-100
		cursor-pointer
		
		${className}
		`} onClick={onClick} aria-label="Help" data-testid="help"><svg aria-hidden={true} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.549023 5.38713C0.382793 4.98591 0.249811 4.51129 0.150072 3.96328C0.050334 3.41527 0.0004637 2.88194 0.0004637 2.36329C0.0004637 1.89357 0.046176 1.46788 0.137603 1.08623C0.22903 0.704586 0.36617 0.401222 0.549023 0.176147C1.81238 0.225076 3.89442 0.249543 6.79514 0.249543C9.67093 0.249543 12.7587 0.225076 16.0583 0.176147C19.3663 0.117431 21.8265 0.0587157 23.439 0C23.6218 0.244647 23.759 0.582263 23.8504 1.01284C23.9501 1.44342 24 1.91803 24 2.43669C24 2.99448 23.9501 3.5327 23.8504 4.05135C23.7507 4.57 23.6135 4.97612 23.439 5.2697C22.2006 5.32841 20.0811 5.38223 17.0807 5.43116C14.0885 5.48009 11.1587 5.50456 8.29121 5.50456C4.71726 5.50456 2.13653 5.46541 0.549023 5.38713Z" fill="white"/>
<path d="M0.54856 14.7083C0.382329 14.3071 0.249347 13.8325 0.149609 13.2845C0.0498703 12.7365 0 12.2031 0 11.6845C0 11.2148 0.0457123 10.7891 0.137139 10.4074C0.228566 10.0258 0.365706 9.72241 0.54856 9.49734C1.81191 9.54627 3.89395 9.57073 6.79467 9.57073C9.67046 9.57073 12.7582 9.54627 16.0579 9.49734C19.3659 9.43862 21.8261 9.37991 23.4385 9.32119C23.6214 9.56584 23.7585 9.90345 23.8499 10.334C23.9497 10.7646 23.9995 11.2392 23.9995 11.7579C23.9995 12.3157 23.9497 12.8539 23.8499 13.3725C23.7502 13.8912 23.613 14.2973 23.4385 14.5909C22.2001 14.6496 20.0807 14.7034 17.0802 14.7524C14.088 14.8013 11.1582 14.8257 8.29075 14.8257C4.71679 14.8257 2.13606 14.7866 0.54856 14.7083Z" fill="white"/>
<path d="M0.54856 23.8826C0.382329 23.4814 0.249347 23.0067 0.149609 22.4587C0.0498703 21.9107 0 21.3774 0 20.8587C0 20.389 0.0457123 19.9633 0.137139 19.5817C0.228566 19.2 0.365706 18.8967 0.54856 18.6716C1.81191 18.7205 3.89395 18.745 6.79467 18.745C9.67046 18.745 12.7582 18.7205 16.0579 18.6716C19.3659 18.6129 21.8261 18.5542 23.4385 18.4954C23.6214 18.7401 23.7585 19.0777 23.8499 19.5083C23.9497 19.9389 23.9995 20.4135 23.9995 20.9321C23.9995 21.4899 23.9497 22.0282 23.8499 22.5468C23.7502 23.0655 23.613 23.4716 23.4385 23.7651C22.2001 23.8239 20.0807 23.8777 17.0802 23.9266C14.088 23.9755 11.1582 24 8.29075 24C4.71679 24 2.13606 23.9609 0.54856 23.8826Z" fill="white"/>
</svg>
</button>	);
};

export default Hamburger;
