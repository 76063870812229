import React, {useState} from 'react';
import Link from 'next/link';
import InfoModal from "@/components/modals/InfoModal";
import Hamburger from "@/components/common/buttons/Hamburger";
import CloseButton from "@/components/common/buttons/CloseButton";
import TpiHorizontal from "@/components/logos/TpiHorizontal";
import {sendGtagEvent} from "@/lib/utils";

const HeaderBar = ({children}) => {
	const [isOpen, setIsOpen] = useState(false)

	const onOpen = () => {
		sendGtagEvent({
			action: 'settings_click',
			category: 'engagement',
			label: 'Settings Click',
			value: 'click',
		})
		setIsOpen(true)
	}
	const onClose = () => {
		sendGtagEvent({
			action: 'settings_close_click',
			category: 'engagement',
			label: 'Settings Click',
			value: 'click',
		});
		setIsOpen(false)
	}
	if (!isOpen) {
		return (
			<div className={`
			inline-flex items-center justify-center
			relative top-0
			w-full
			bg-primary bg-opacity-50
			`}>
				<Link href="/" className={'w-32 h-fit p-2'}>
					<TpiHorizontal width={'100%'} height={'100%'} />
				</Link>
				<Hamburger className={'hover:animate-hop absolute top-2 right-0'} onClick={onOpen} />
			</div>
		);
	}
	return (
		<div className={`
		fixed
		flex flex-col items-center
		top-0 left-0 bottom-0 right-0
		p-2
		z-[1000]
		overflow-y-auto
		bg-black bg-opacity-90
		backdrop-blur
		`}>
			<CloseButton className={'absolute top-3 right-3'} onClick={onClose} />
			<InfoModal />
		</div>
	);
};

export default HeaderBar;
